.app {
  display: flex;
  padding: 20px;
  background-color: #f7f7f7;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.profile-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info {
  text-align: center;
  margin-top: 20px;
}

.profile-info h2 {
  color: #333;
}

.subjects,
.pending-list {
  margin-top: 20px;
  text-align: left;
}

.list-item {
  background-color: transparent;
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.list-item:hover {
  background-color: #f0f0f0;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.content {
  flex-grow: 1;
  padding-left: 40px;
}

.search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  /* background-color: #0056b3; */
  border: 1px solid #ccc;
  border-radius: 25px;
}

.search input {
  flex-grow: 1;
  padding: 8px 12px;
  /*border: 1px solid #ccc;*/
  border-radius: 50px;
  width:50%;
}

.tabs {
  display: flex;
  margin-top: 10px;
}

.tabs span {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.tabs span.active {
  background-color: #007bff;
  color: #fff;
}

.tabs span:hover {
  background-color: #e0e0e0;
}

.papers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.paper {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.paper:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.paper h4 {
  color: #333;
}